import React from "react"
import { graphql } from "gatsby"

import { vw, paths, patterns, colors, genID } from "../utilities/helpers"
import SEO from "../utilities/seo"

import Block from "../components/block"
import Bit from "../components/bit"
import Glow from "../components/glow"
import Preface from "../components/preface"
import Submenu from "../components/submenu"
import Slice from "../components/slice"
import Meta from "../components/meta"
import Grid from "../components/grid"
import Next from "../components/next"
import Parallax from "../components/parallax"
import Body from "../components/wrap"
import Footer from "../components/footer"

const id = genID()

function renderPreface(content, index) {
  const item = content.node.data

  return (
    <Block key={index} className="page-preface">
      <svg viewBox="0 0 1320 720" className="page-preface__image">
        <defs>
          <linearGradient
            id={id + "f"}
            gradientUnits="objectBoundingBox"
            gradientTransform="rotate(90)"
          >
            <stop offset="0" stopColor="#fff" />
            <stop offset="1" stopColor="#000" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id={id + "g"}
            gradientUnits="objectBoundingBox"
            gradientTransform="rotate(90)"
          >
            <stop offset="0" stopColor="#4744E5" />
            <stop offset="1" stopColor="#000" stopOpacity="0" />
          </linearGradient>
          <mask id={id + "m"}>
            <path fill={"url(#" + id + "f)"} x="100px" d={paths.c} />
          </mask>
        </defs>
        <g transform="translate(660,0)">
          <path fill={"url(#" + id + "g)"} d={paths.h} />
        </g>
        <g mask={"url(#" + id + "m)"}>
          <image
            xlinkHref={item.image.url}
            x="0"
            y="0"
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMid slice"
          ></image>
        </g>
      </svg>
      <Preface
        title={item.heading.text}
        summary={item.preface.text}
        meta={[
          {
            key: 1,
            type: "location",
            label: item.venue.text,
          },
          {
            key: 2,
            type: "time",
            label: item.timestamp,
          },
        ]}
      />

      <Glow royal top="-15%" left={vw(-4)} delay="0" />
      <Bit cross top="25%" right="45%" />
      <Bit ring top="45%" right="25%" />

      <Glow warm bottom="-50%" right={vw(-4)} delay="0.75" />
      <Bit zig top="55%" right="35%" />
    </Block>
  )
}

function sanitizeString(str) {
  str = str.replace(/[^a-z0-9áéíóúñü,_-]/gim, "")
  return str.trim()
}

function renderSlices(content, index) {
  var type = content.slice_type
  var level = ""
  var size = ""
  var title = ""
  var subtitle = ""
  var venue = ""
  var image = ""
  var time = ""
  var html = ""
  var id = ""
  var label = ""

  if (type === "image_list") {
    var cells = []

    content.items.forEach(function (ele, pos) {
      var cellTitle = ""
      var cellSubtitle = ""
      var cellImage = ""
      var cellIcon = ""
      var cellType = ""

      if (type === "image_list") {
        cellTitle = ele.title.text
        cellSubtitle = ele.subtitle.text
        cellImage = ele.list_image.url
        cellIcon = ""
      }

      cells[pos] = {
        key: pos,
        type: cellType,
        subtitle: cellSubtitle,
        fg: cellImage,
        icon: cellIcon,
        fill: colors.green,
        bg: patterns.green,
      }
    })

    return (
      <Slice grid key={index}>
        <Grid col={4} cells={cells} />
      </Slice>
    )
  }

  if (type === "section") {
    var speakers = []
    var node = content.primary

    level = node.level

    title = node.section_title.text
    subtitle = node.subtitle.text
    image = node.section_image.url
    html = node.section_content.html
    venue = node.venue.text
    time = node.timestamp
    label = node.menu_label.text

    level === "1" ? (size = "large") : (size = "medium")

    if (level === "1") {
      id = "m" + sanitizeString(node.menu_label.text).replace(/\s/g, "")
    }

    // if (content.items.length > 0){
    //   for(var i = 0; i < content.items.length; i++){
    //     speakers[i] = {
    //       'title': content.items[i].speakers.document['data'].name.text,
    //       'subtitle': content.items[i].speakers.document['data'].title.text,
    //       'organization': content.items[i].speakers.document['data'].organization.text,
    //       'fg': content.items[i].speakers.document['data'].photo.url,
    //       'key': i
    //     }
    //   }

    // } else {
    //   speakers = false
    // }

    speakers = false

    return (
      <section id={id} key={index}>
        <Slice
          length={html.length}
          size={size}
          bg={patterns.green}
          fg={image}
          title={title}
          subtitle={subtitle}
          speakers={speakers}
        >
          {html && <div dangerouslySetInnerHTML={{ __html: html }} />}

          {venue && time && (
            <Meta
              data={[
                {
                  key: 1,
                  type: "location",
                  label: venue,
                },
                {
                  key: 2,
                  type: "time",
                  label: time,
                },
              ]}
            />
          )}
        </Slice>
      </section>
    )
  }
}

export default ({ data }) => {
  const menu = []
  const menuItems = data.allPrismicPage.edges[0].node.data.body1

  menuItems.forEach(function (ele, index) {
    if (ele.primary) {
      const item = ele.primary

      if (item.level === "1") {
        var label = item.menu_label.text
        var id = sanitizeString(label).replace(/\s/g, "")

        menu[index] = {
          key: index,
          label: label,
          anchor: "#m" + id,
        }
      }
    }
  })

  return (
    <>
      <main>
        <SEO
          description={data.allPrismicPage.edges[0].node.data.preface.text}
          title={data.allPrismicPage.edges[0].node.data.label.text}
          image={data.allPrismicPage.edges[0].node.data.image.url}
        />

        <Submenu items={menu} className="submenu" />

        {data.allPrismicPage.edges.map(renderPreface)}

        <Block>
          <Glow royal top="-5%" left={vw(-4)} delay="0" />
          <Bit cross top="15%" left="15%" />

          <Glow cool top="15%" right={vw(-6)} delay="0.75" />
          <Bit ring top="15%" right="15%" />

          <Glow warm top="50%" left={vw(-6)} delay="0" />
          <Bit ring top="50%" left="15%" />
          <Bit ring top="75%" left="15%" />

          <Glow cool bottom="-5%" right={vw(-4)} delay="0.75" />
          <Bit royal top="100%" right="15%" />

          <Body className="page-content">
            {data.allPrismicPage.edges[0].node.data.body1.map(renderSlices)}
          </Body>
        </Block>

        <Next to="/speakers" title="Speakers" />

        <Parallax b />
      </main>
      <Footer />
    </>
  )
}

export const query = graphql`
  query programQuery {
    allPrismicPage(filter: { uid: { eq: "program" } }) {
      edges {
        node {
          data {
            body1 {
              ... on PrismicPageDataBody1Section {
                slice_type
                items {
                  speakers {
                    document {
                      ... on PrismicSpeaker {
                        data {
                          name {
                            text
                          }
                          organization {
                            text
                          }
                          photo {
                            url
                          }
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                }
                primary {
                  level
                  section_content {
                    html
                  }
                  section_image {
                    url
                  }
                  section_title {
                    text
                  }
                  subtitle {
                    text
                  }
                  timestamp(formatString: "dddd DD MMMM YYYY HH:ma")
                  venue {
                    text
                  }
                  menu_label {
                    text
                  }
                }
              }
            }
            preface {
              text
            }
            timestamp(formatString: "dddd DD MMMM YYYY HH:ma")
            venue {
              text
            }
            image {
              url
            }
            heading {
              text
            }
            label {
              text
            }
          }
          uid
        }
      }
    }
  }
`
